/**
 * Aside menu config
 *
 * To more info about config of menu items go to knowledgebase, lms, review (in review you can see menu for Head of department)
 */
import store from "@/core/services/store";

export default {
    submenu: {
        list: {
            router_link: 'WorkersList',
            icon: "flaticon2-plus",
            text_code: 'menu.select',
            text: 'WorkersList',
        },
        my: {
            router: {
                name: 'ShowFeedback',
                params: {id: 0}
            },
            icon: "flaticon-user",
            text_code: 'menu.my',
            text: 'ShowFeedback',
        },
        admin: {
            router_link: 'AllReports',
            icon: "flaticon-users",
            text: 'GoalSubordinates',
            text_code: 'menu.admin',
            active: function() {
                const user = store.getters.currentUser;
                return user ? user.is_admin : false;
            },
            menu: {
                all: {
                    router_link: 'AllReports',
                    icon: "flaticon-list-2",
                    text_code: 'menu.all_reports',
                    text: 'AllReports',
                },
                /*limit: {
                    router_link: 'AnonymLimit',
                    icon: "flaticon2-plus",
                    text_code: 'feedback.menu.limits',
                    text: 'AnonymLimit',
                }*/
            }
        }
    }
};

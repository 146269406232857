import Vue from "vue";

const feedback = {
    namespaced: true,
    state: {
        allFeedbacks: {},
        usersFeedbacks: {},
        showFeedbackDispatchId: 0,
        allFeedbacksDispatchId: 0 
    },
    getters: {
        getAllFeedbacks: state => () => state.allFeedbacks,
        getUsersFeedbacks: state => () => state.usersFeedbacks
    },
    mutations:{
        SET_ALL_FEEDBACKS(state, {data, pagination}){
            Vue.set(state.allFeedbacks, "data", data);
            Vue.set(state.allFeedbacks, "pagination", pagination);
        },
        SET_USERS_FEEDBACKS(state, {data, pagination}){
            Vue.set(state.usersFeedbacks, "data", data);
            Vue.set(state.usersFeedbacks, "pagination", pagination);
        }
    },
    actions: {
        showFeedback({state, commit}, data){
            let sort = [];
            if(data.sort){
              sort.push({ [data.sort.sort_column] : data.sort.sort_type});
            } else {
              sort = [
                {"created_at_table": {"order": "desc"}}
              ];
            }
            let page = data.page || 1;
            let query = data.query || "";
            let filter = data.filter || [];
            state.showFeedbackDispatchId++;
            let dispatchId = state.showFeedbackDispatchId;
            return this.$app.$api.feedback.show({id: data.userId, page: page, per_page: 10, search: query, sort: sort, filter: filter})
                .then((response) => {
                    if(state.showFeedbackDispatchId == dispatchId){
                        let pagination = {};
                        for(let index in response.data){
                            if(index != "data"){
                                pagination[index] = response.data[index];
                            }
                        }
                        commit("SET_USERS_FEEDBACKS", {"data": response.data.data, "pagination": pagination});
                    }
                });
        },
        saveFeedback(state, data) {
            return new Promise((resolve, reject) => {
                this.$app.$api.feedback.store(data)
                    .then((resp) => {
                        resolve(resp);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        loadCompetence(state, data){
            return new Promise((resolve) => {
                this.$app.$api.feedback.loadCompetence(data).then(response => {
                    resolve(response);
                });
            });
        },
        loadIndicator(state, data){
            return new Promise((resolve) => {
                this.$app.$api.feedback.loadIndicator(data)
                    .then(response => {
                        resolve(response);
                    });
            });
        },
        loadAllFeedbacks({state, commit}, data){
            let sort = [];
            if(data.sort){
              sort.push({ [data.sort.sort_column] : data.sort.sort_type});
            } else {
              sort = [
                {"created_at_table": {"order": "desc"}}
              ];
            }
            let page = data.page || 1;
            let query = data.query || "";
            let filter = data.filter || [];
            state.allFeedbacksDispatchId++;
            let dispatchId = state.allFeedbacksDispatchId;
            return this.$app.$api.feedback.admin.report({page: page, per_page: 10, search: query, sort: sort, filter: filter})
                .then((response) => {
                    if(state.allFeedbacksDispatchId == dispatchId){
                        let pagination = {};
                        for(let index in response.data){
                        if(index != "data"){
                            pagination[index] = response.data[index];
                        }
                        }
                        commit("SET_ALL_FEEDBACKS", {"data": response.data.data, "pagination": pagination});
                    }
                });
        }
    }
};

export default feedback;
export default {
  menu: {
    main: "Feedback",
    select: "Leave feedback",
    my: "My feedback",
    admin: "Admin bar",
    all_reports: "All reports",

    //department_reports: "Your subordinates",
    //limits: "Anonymous messages limit",
    //users: "Users"
  },
  views: {
    workers_list: {
      info: "Start typing the first or last name of the employee.",
      send: "Create feedback"
    },
    send_feedback: {
      thank: "Thank you",
      been_sent: "You left feedback",
      send_more: "Send again",
      feedback_about: "Leave feedback about",
      well_done: "Well done",
      try_harder: "Try harder",
      write: "Write your feedback",
      error_occured: "An error occurred while filling out the fields",
      anon: "Send anonymously",
      anon_limit: "Anonymous message limit exceeded",
      send: "Leave feedback",
      add_comment: "Add a comment",
      message_lenght: "Message length must be greater than 6 characters and less than 2000",
    },
    grid: {
      block: "Competence",
      change: "Change",
      comment: "Comment",
      competence: "Indicator",
      date: "Date and time",
      user_from: "Sender",
      user_to: "Recipient",
      value: "Value",
      visibility: "Visibility",
      locale: {
        page: "Page",
        of: "of",
        to: "to",
        loadingOoo: "Loading...",
        noRowsToShow: "No Rows To Show",
        filterOoo: "Filter...",
        applyFilter: "Apply",
        equals: "Equals",
        notEqual: "Not equal",
        lessThan: "Less than",
        greaterThan: "Greater than",
        lessThanOrEqual: "Less than or equal",
        greaterThanOrEqual: "Greater than or equal",
        inRange: "In range",
        contains: "Contains",
        notContains: "Not contains",
        startsWith: "Starts with",
        endsWith: "Ends with",
        andCondition: "AND",
        orCondition: "OR"
      }
    },
    show_feedback: {
      info: "List of feedback on",
      anon: "Anonymous",
      user_deleted: "deleted",
    },
    all_reports: {
      all: "All feedbacks",
      error_status: "Error occured. It is impossible to refresh status.",
    },
    table: {
      filter: "Click to use filter",
      user_from: "Sender",
      user_to: "Recepient",
      competence: "Competence",
      reset: "Reset",
      search: "Search",
      gradedLang: "Sender",
      gradingLang: "Recepient",
      typeCompetenceLang: "Competence type",
      competenceLang: "Competence",
      indicatorLang: "Indicator"
    },

    //old
    sync: {
      sync: "Synchronization",
      upd: "Updates",
      finish: "Updating completed"
    },
    form: {
      add: "Supplement",
      select_competence: "Select the indicator",
      select_partition: "Select the competence",
      select_block: "Select the block",
      to_the_list: "To the list",
    },
    report: {
      all: "All reports",
      department: "List of feedback on department employees",
      error_excel: "An error occurred while loading Excel",
      error_status: "An error has occurred. Unable to update status.",
      load_to_excel: "Load to Excel",
      your_excel: "Your Excel"
    },
    limits: {
      day: "Day",
      fail_response: "An error has occurred. Unable to get the value of limits from the database.",
      month: "Month",
      new_gap: "You set a new time span!",
      set_gap: "Choose time span",
      set_number: "Set the number of valid anonymous messages",
      title: "Setting the limit for sending anonymous messages",
      week: "Week"
    }
  },

};

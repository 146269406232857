export default {
  menu: {
    select: "Отзыв коллеге",
    my: "Мои отзывы",
    admin: "Панель администратора",
    all_reports: "Посмотреть все отчёты",

    //department_reports: "Отчёты ваших подчинённых",
    //limits: "Ограничение анонимных сообщений",
    //users: "Пользователи"
  },
  views: {
    workers_list: {
      info: "Начните вводить имя или фамилию сотрудника.",
      send: "Перейти к отзыву"
    },
    send_feedback: {
      thank: "Спасибо",
      been_sent: "Обратная связь отправлена",
      send_more: "Отправить ещё",
      feedback_about: "Оставить отзыв о",
      well_done: "Молодец",
      try_harder: "Старайся",
      write: "Напишите ваш отзыв",
      error_occured: "Произошла ошибка при заполнении полей",
      anon: "Отправить анонимно",
      anon_limit: "Лимит анонимных сообщений превышен",
      send: "Отправить отзыв",
      add_comment: "Добавьте комментарий",
      message_lenght: "Длина сообщения должна быть больше 6 символов и меньше 2000",
    },
    grid: {
      block: "Компетенция",
      change: "Изменить",
      comment: "Комментарий",
      competence: "Индикатор",
      date: "Дата и время",
      user_from: "Отправитель",
      user_to: "Получатель",
      value: "Оценка",
      visibility: "Видимость",
      locale: {
        page: "Страница",
        of: "из",
        to: "до",
        loadingOoo: "Загрузка...",
        noRowsToShow: "Нет данных",
        filterOoo: "Фильтрация...",
        applyFilter: "Применить",
        equals: "Соответсвует",
        notEqual: "Не соответсвует",
        lessThan: "Меньше чем",
        greaterThan: "Больше чем",
        lessThanOrEqual: "Меньше или равно",
        greaterThanOrEqual: "Больше или равно",
        inRange: "В диапазоне",
        contains: "Содержит",
        notContains: "Не содержит",
        startsWith: "Начинается с",
        endsWith: "Заканчивается на",
        andCondition: "И",
        orCondition: "ИЛИ"
      }
    },
    show_feedback: {
      info: "Список отзывов на",
      anon: "Аноним",
      user_deleted: "удалён",
    },
    all_reports: {
      all: "Все отзывы",
      error_status: "Произошла ошибка. Невозможно обновить статус.",
    },
    table: {
      filter: "Нажмите чтобы использовать фильтр",
      user_from: "Отправитель",
      user_to: "Получатель",
      competence: "Компетенция",
      reset: "Сбросить",
      search: "Поиск",
      gradedLang: "Получатель",
      gradingLang: "Отправитель",
      typeCompetenceLang: "Тип компетенции",
      competenceLang: "Компетенция",
      indicatorLang: "Индикатор"
    },


    //old
    sync: {
      sync: "Синхронизация",
      upd: "Обновлений",
      finish: "Обновления завершены"
    },
    form: {
      add: "Дополните оценку",
      select_competence: "Выберите индикатор",
      select_partition: "Выберите компетенцию",
      select_block: "Выберите блок компетенций",
      to_the_list: "К списку",
    },
    report: {
      department: "Список отзывов на сотрудников отделов",
      error_excel: "Ошибка при создании Excel",
      load_to_excel: "Выгрузить в Excel",
      your_excel: "Ваш Excel"
    },
    limits: {
      day: "День",
      fail_response: "Произошла ошибка. Невозможно получить значение ограничений из базы.",
      month: "Месяц",
      new_gap: "Вы установили новый промежуток!",
      set_gap: "Выберите промежуток времени",
      set_number: "Установите количество допустимых анонимных сообщений",
      title: "Установка лимита отправки анонимных сообщений",
      week: "Неделя"
    }
  },

};

export default [
    {
        path: "/",
        alias: "/feedback",
        redirect: { name: "WorkersList" },
        name: "root",
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: '/select',
                component: () => import('./views/WorkersList.vue'),
                name: 'WorkersList',
                beforeEnter: (to, from, next) => {
                    var is_next = true;
                    if (from.name === null) {
                        document.cookie.split('; ').forEach(function(item) {
                            let cookie = item.split('=');
                            if (cookie[0]==='UserFeedback') {
                                is_next=false;
                                next({name:'SendFeedback', params: { id: cookie[1] }});
                            }
                        });
                    }
                    if (is_next) {
                        next();
                    }
                }
            },
            {
                path: '/user/report/:id',
                component: () => import('./views/ShowFeedback.vue'),
                name: 'ShowFeedback',
                props: true
            },
            {
                path: '/form/user/:id',
                component: () => import('./views/SendFeedback.vue'),
                name: 'SendFeedback',
                props: true
            },
            {
                path: '/admin/reports',
                component: () => import('./views/AllReports.vue'),
                name: 'AllReports'
            },
            /*{
                path: '/admin/limits',
                    component: () => import('./components/Administrator/AnonymLimit.vue'),
                name: 'AnonymLimit'
            }*/
        ]
    }
];
